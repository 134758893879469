var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "zg-modal",
    { attrs: { visible: _vm.modal.active }, on: { close: _vm.hide } },
    [
      _c("template", { slot: "title" }, [
        _vm.message.title
          ? _c("span", [
              _vm._v("\n      " + _vm._s(_vm.message.title) + "\n    "),
            ])
          : _c("span", { staticClass: "modal-title" }),
      ]),
      _vm._v(" "),
      _vm.message.text
        ? _c("template", { slot: "text" }, [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.modalText) } }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.message.button
        ? _c(
            "template",
            { slot: "buttons" },
            [
              _vm.message.button === "ok"
                ? _c(
                    "zg-button",
                    {
                      attrs: {
                        icons: { right: "arrow-forward" },
                        "data-name": "ok-btn",
                        type: "button",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.hide.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n      " + _vm._s(_vm.content.ok) + "\n    ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.message.button === "reAuthentication"
                ? _c(
                    "div",
                    { key: "reAuthentication", staticClass: "authentication" },
                    [
                      _c(
                        "zg-button",
                        {
                          attrs: {
                            icons: { right: "arrow-forward" },
                            "data-name": "retry-btn",
                            type: "button",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.retryAuthentication(
                                "sme/lookup/fetchEngagements"
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.content.identify) +
                              "\n      "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.message.button === "authentication"
                ? _c(
                    "div",
                    { key: "authentication", staticClass: "authentication" },
                    [
                      _c(
                        "zg-button",
                        {
                          attrs: {
                            icons: { right: "arrow-forward" },
                            "data-name": "retry-btn",
                            type: "button",
                          },
                          on: { click: _vm.retryAuthentication },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.content.retry) +
                              "\n      "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "zg-button",
                        {
                          attrs: {
                            icons: { right: "close" },
                            "data-name": "cancel-btn",
                            type: "button",
                          },
                          on: { click: _vm.cancelAuthentication },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.content.cancel) +
                              "\n      "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.message.button === "login"
                ? _c(
                    "zg-button",
                    {
                      attrs: {
                        icons: { right: "arrow-forward" },
                        "data-name": "login-btn",
                        type: "button",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.goToLogin.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n      " + _vm._s(_vm.content.login) + "\n    ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.message.button === "myPages"
                ? _c(
                    "zg-button",
                    {
                      attrs: {
                        icons: { right: "arrow-forward" },
                        "data-name": "login-btn",
                        type: "button",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.goToLogin.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n      " + _vm._s(_vm.content.myPages) + "\n    "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.message.button === "confirmation"
                ? [
                    _c("zg-button", { on: { click: _vm.confirmAction } }, [
                      _vm._v(
                        "\n        " + _vm._s(_vm.content.yes) + "\n      "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "zg-button",
                      {
                        attrs: { icons: { right: "close" } },
                        on: { click: _vm.hide },
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.content.no) + "\n      "
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.message.button === "confirmCallback"
                ? [
                    _c(
                      "zg-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.confirmCallback("yes")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.content.yes) + "\n      "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "zg-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.confirmCallback("no")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.content.no) + "\n      "
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
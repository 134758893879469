<template>
  <zg-modal
    :visible="modal.active"
    @close="hide"
  >
    <template slot="title">
      <span v-if="message.title">
        {{ message.title }}
      </span>
      <span
        v-else
        class="modal-title"
      />
    </template>

    <template
      v-if="message.text"
      slot="text"
    >
      <div v-html="modalText" />
    </template>

    <template
      v-if="message.button"
      slot="buttons"
    >
      <zg-button
        v-if="message.button === 'ok'"
        :icons="{ right: 'arrow-forward' }"
        data-name="ok-btn"
        type="button"
        @click.native="hide"
      >
        {{ content.ok }}
      </zg-button>

      <div
        v-if="message.button === 'reAuthentication'"
        key="reAuthentication"
        class="authentication"
      >
        <zg-button
          :icons="{ right: 'arrow-forward' }"
          data-name="retry-btn"
          type="button"
          @click="retryAuthentication('sme/lookup/fetchEngagements')"
        >
          {{ content.identify }}
        </zg-button>
      </div>

      <div
        v-if="message.button === 'authentication'"
        key="authentication"
        class="authentication"
      >
        <zg-button
          :icons="{ right: 'arrow-forward' }"
          data-name="retry-btn"
          type="button"
          @click="retryAuthentication"
        >
          {{ content.retry }}
        </zg-button>

        <zg-button
          :icons="{ right: 'close' }"
          data-name="cancel-btn"
          type="button"
          @click="cancelAuthentication"
        >
          {{ content.cancel }}
        </zg-button>
      </div>

      <zg-button
        v-if="message.button === 'login'"
        :icons="{ right: 'arrow-forward' }"
        data-name="login-btn"
        type="button"
        @click.native="goToLogin"
      >
        {{ content.login }}
      </zg-button>

      <zg-button
        v-if="message.button === 'myPages'"
        :icons="{ right: 'arrow-forward' }"
        data-name="login-btn"
        type="button"
        @click.native="goToLogin"
      >
        {{ content.myPages }}
      </zg-button>

      <template v-if="message.button === 'confirmation'">
        <zg-button
          @click="confirmAction"
        >
          {{ content.yes }}
        </zg-button>

        <zg-button
          :icons="{ right: 'close' }"
          @click="hide"
        >
          {{ content.no }}
        </zg-button>
      </template>

      <template v-if="message.button === 'confirmCallback'">
        <zg-button
          @click="confirmCallback('yes')"
        >
          {{ content.yes }}
        </zg-button>

        <zg-button
          @click="confirmCallback('no')"
        >
          {{ content.no }}
        </zg-button>
      </template>
    </template>
  </zg-modal>
</template>

<script>
  import { historyBack } from '@ocp-zmarta/zmarta-cl'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'Modal',
    components: {
      ZgButton: () => import(/* webpackChunkName: 'zc/zg-button' */
        '@zc/components/ZgButton/ZgButton'),
      ZgModal: () => import(/* webpackChunkName: 'zc/zg-modal' */
        '@zc/components/ZgModal/ZgModal')
    },
    computed: {
      ...mapGetters('content', ['getContent']),
      ...mapGetters('modal', ['getModal']),
      ...mapGetters('messages', ['getModalMessage']),
      ...mapGetters('form', ['getForm']),
      content () {
        return this.getContent?.common ?? {}
      },
      modal () {
        return this.getModal ?? {}
      },
      message () {
        return this.getModalMessage ?? {}
      },
      modalText () {
        return this.message?.text
          ?.replace('{totalDebtMonthlyCost}', this.getForm?.debts?.totalDebtMonthlyCost?.value)
      }
    },
    methods: {
      ...mapActions('modal', ['hideModal']),
      ...mapActions('cl/formSteps', ['goToNextStep']),
      ...mapActions('cl/analytics', ['buttonTracking']),
      async hide () {
        if (this.CL) {
          await this.buttonTracking({ label: 'modal-close' })
          historyBack()
        }

        await this.hideModal()
      },
      async goToLogin () {
        await this.buttonTracking({ label: 'modal-login' })
        this.EVENT_BUS.$emit('application:go-to-my-account')
      },
      async retryAuthentication (action) {
        await this.buttonTracking({ label: 'modal-retry-authentication' })

        this.EVENT_BUS.$emit('authentication:initiate', {
          eidProvider: this.SWEDEN ? this.EID_PROVIDERS.BANKID : this.EID_PROVIDERS.SIGNICAT,
          authenticatedAction: action
        })
      },
      async cancelAuthentication () {
        await this.buttonTracking({ label: 'modal-cancel-authentication' })
        this.EVENT_BUS.$emit('authentication:cancel')
      },
      async confirmAction () {
        if (this.CL) {
          await this.goToNextStep()
        }

        await this.hideModal()
      },
      async confirmCallback (action) {
        await this.buttonTracking({ label: `high-monthly-cost-${action}` })
        await this.message.callbacks[action]?.()
        await this.hideModal()
      }
    }
  }
</script>

<style lang="scss" scoped>
  :deep(span.code) {
    @include type-body-xs;

    display: block;
    font-weight: font-weight(bold);
    margin: 0;
  }
</style>
